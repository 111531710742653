












































































import TestList from '@/components/TestList.vue';

import { Component, Vue, PropSync } from 'vue-property-decorator';
import { ITestResult, IFile, ISystem, IAssay } from '@/interfaces';
import { dispatchGetSystems, dispatchUpdateUser } from '@/store/admin/actions';
import { dispatchCheckApiError } from '@/store/main/actions';
import { readAdminOneSystem } from '@/store/admin/getters';

import { readToken } from '@/store/main/getters';
import { api } from '@/api';

@Component({
  components: {
    TestList,
  },
})
export default class ViewSystem extends Vue {
  public tests: ITestResult[] = [];
  public logs: IFile[] = [];

  public logHeaders = [
          { text: 'Filename', value: 'filename' },
          { text: 'Size', value: 'file_size' },
          { text: 'Upload Date', value: 'upload_date' },
        ];

  public assayHeaders = [
          { text: 'Name', value: 'name' },
          { text: 'Version', value: 'assay_version' },
          { text: 'Comments', value: 'comments' },
        ];

  public logData: string[] = [];

  // @PropSync('system', { type: Object }) syncedSystem!: ISystem

  public async mounted() {
    await dispatchGetSystems(this.$store);
    // console.log(this.$router.currentRoute.params.id);
    if (this.system) {
      this.getLogs();
      this.getTests();
    }
  }

  public cancel() {
    this.$router.back();
  }

  public showLog(item, other) {
    this.getLog(item.id);
  }

  public async getTests() {
    try {
      const response = await api.getTestsForSystem(
        readToken(this.$store),
        this.system?.id ?? -1,
      );
      if (response) {
          this.tests = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  /*public async getAssays() {
    try {
      const response = await api.getAssaysForSystem(
        readToken(this.$store),
        this.system?.id ?? -1
      );
      if (response) {
          this.logs = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }*/

  public async getLogs() {
    try {
      const response = await api.getLogsForSystem(
        readToken(this.$store),
        this.system?.id ?? -1,
      );
      if (response) {
          this.logs = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public async getLog(logId) {
    try {
      const response = await api.getFile(
        readToken(this.$store),
        logId,
      );
      if (response) {
          this.logData = response.data.split('\n');
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  get system() {
    return readAdminOneSystem(this.$store)(+this.$router.currentRoute.params.id);
  }

}
